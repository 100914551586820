 
 .Modal {  
   left:0;
   right: 0;   
   top:0;
   bottom: 0;
   position: absolute; 
   z-index: 500;
   background-color: rgba(0, 0, 0, 0.00); 
   width: 100%;
   box-shadow: 0px -3px 3px rgba(0, 0, 0, 0.00); 
   box-sizing: border-box;
   transition: all 0.3s ease-out; 
   overflow-y: hidden;  
   flex-flow: column;
   overflow-x: hidden; 
   justify-content: center;
   align-items: center;
   display: flex; 
   -webkit-overflow-scrolling: touch;
   height: 100%; 

}